html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Lato", sans-serif;
  padding: 0;
  margin: 0;
}

*:focus {
  outline: none !important;
}

@media print {
  .header, .header__cart, .preferences-toggle, .print-hidden {
    display: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:active,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent !important;
}


// Override default variables before the import
$grid-gutter-width: 24px !default;
$spacer: 12px !default;

$body-bg: transparent;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 958px,
  md: 959px,
  lg: 960px,
  xl: 1140px
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
